import React from 'react';
import StyledContainer from '../components/organisms/StyledContainer';
import ProductTypeCard from '../components/ProductTypeCard';
import ProductTypeHero from '../components/ProductTypeHero';
import ProductTypeLocation from '../components/ProductTypeLocation';
import { graphql } from 'gatsby';

import ProductTypeSection from '../components/ProductTypeSection';
import SEO from '../components/SEO';

export default function ProductTypePage({data}){
    return(
        <>
            <SEO 
                title="Accessories"
                description="Ronin is also committed to providing the greatest selection of weed accessories around. 
                Whether you're looking for rolling papers, a new weed grinder, want to upgrade to better weed 
                pipes or are even considering taking the leap to a weed vaporizer, we've got you covered."
            />
            <ProductTypeHero
                title="Cannabis Accessories"
                subtitle="Offering the Best Weed Accessories in Southern Ontario"
                description={[
                    `There's a lot more to being the most trusted cannabis retail store in Ontario than just offering 
                    the best weed strains and providing top-quality customer service.`,

                    `Ronin is also committed to providing the greatest selection of weed accessories around. 
                    Whether you're looking for rolling papers, a new weed grinder, want to upgrade to better weed 
                    pipes or are even considering taking the leap to a weed vaporizer, we've got you covered.`,
                ]}
                bannerImage={data.accessoriesBackgroundImage}
            />
            <StyledContainer padding="120px">
                <ProductTypeSection products={data.accessories.nodes}/>
            </StyledContainer>
            <ProductTypeLocation/>
        </>
    );
}

export const query = graphql`
    query{
        accessories: allSanityProductType(filter: {productpage: {eq: "Accessories"}}) {
                nodes {
                    name
                    productpage
                    imagePlusAltHeader {
                        image {
                            asset {
                                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, height: 250)
                            }
                        }
                        alt
                    }
                    _rawContent
                    slug
                }
        }

        accessoriesBackgroundImage: file(relativePath: { eq: "accessories-bg.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
        }
    }
`;